import '../../bootstrap/bugsnag.bootstrap';
import '../../bootstrap/site-header.bootstrap';
import Swiper, { Pagination } from 'swiper';
import { touchElement } from 'lambda-dom';
import { getDisplayMode, getHeaderTheme, setHeaderDisplay, setHeaderTheme } from '../../site-header/site-header.lib';
import { sizeHeroVideo } from './lib/cover-video.lib';
import { windowSizes$ } from '../../bootstrap/window-size-tracking.bootstrap';
import '../../services/banner/banner.service';
import '../../services/news-subscription-form/news-subscription-form.service';
import '../../services/browser-support/browser-support.service';
import { HeaderDisplay, HeaderTheme } from '../../site-header/site-header.types';
import { ScrollBlockers } from '../../lib/scrolling.lib';
import { videoPlayerOverLayService } from '../../services/video-player/video-player.service';


// ------------------------------------------------------------------------------
//      Sliders
// ------------------------------------------------------------------------------

window.onload = () => {

    new Swiper('.cases-mobile', {
        modules: [Pagination],
        pagination: {
            el: '.cases-mobile .swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => `<span class="${className}"></span>`,
        },
        height: 370,
    });

    new Swiper('.news-items--wrapper.swiper', {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 0,
        breakpoints: {
            767: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
        },

        pagination: {
            el: '.news-items--wrapper.swiper .swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => `<span class="${className}"></span>`,
        },
    });

    const vacancySlider = new Swiper('.vacancies--wrapper.swiper', {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
            el: '.vacancies--wrapper.swiper .swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => `<span class="${className}"></span>`,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
        },
    });

    const el = document.querySelector('.vacancies--wrapper.swiper');
    el?.addEventListener('touchmove', () => {
        if (document.body.clientWidth > 767) {
            // @ts-ignore
            vacancySlider.touchEventsData.isTouched = false;
            // @ts-ignore
            vacancySlider.touchEventsData.startMoving = true;
            // @ts-ignore
            vacancySlider.touchEventsData.isScrolling = true;
        }
    }, {
        capture: true,
    });

    touchElement('#sustainability-cases-slider', (container: HTMLElement) => new Swiper(container, {
        modules: [Pagination],
        spaceBetween: 60,
        pagination: {
            el: container.querySelector<HTMLElement>('.swiper-pagination')!,
            type: 'bullets',
            clickable: true,
            renderBullet: (index, className) => `<span class="${className}"></span>`,
        },
    }));
};

// ------------------------------------------------------------------------------
//      Navigation theme handling
// ------------------------------------------------------------------------------

(() => {
    const heroContainer = document.getElementById('homepage-hero')!;

    const observer = new IntersectionObserver(([element]) => {

        const ratio = element.intersectionRatio * 100;

        if (ratio >= 85) {
            setHeaderDisplay('none');
            setHeaderTheme(HeaderTheme.TRANSPARENT_WHITE);
        } else {
            setHeaderDisplay('brand-logo');
            setHeaderTheme(HeaderTheme.WHITE);
        }
    }, { threshold: [1, 0.85, 0.1, 0] });

    observer.observe(heroContainer);
})();

// ------------------------------------------------------------------------------
//      Hero video sizing
// ------------------------------------------------------------------------------

windowSizes$.subscribe(sizeHeroVideo);

// ------------------------------------------------------------------------------
//      Popup video
// ------------------------------------------------------------------------------

(() => {
    const modal = document.getElementById('aalberts-video-modal') !;
    const modalTrigger = document.getElementById('aalberts-video-trigger') !;
    const video = document.getElementById('aalberts-video') as HTMLVideoElement;
    const closeButton = document.getElementById('aalberts-video-close') !;

    let themeAfterClose: HeaderTheme;
    let displayModeAfterClose: HeaderDisplay;

    modalTrigger.addEventListener('click', () => {
        ScrollBlockers.add('homepage-video');
        modal.classList.add('active');
        themeAfterClose = getHeaderTheme();
        displayModeAfterClose = getDisplayMode();
        setHeaderTheme(HeaderTheme.BLACK);
        setHeaderDisplay('brand-logo');
        video.play();
    });

    closeButton.addEventListener('click', () => {
        ScrollBlockers.remove('homepage-video');
        modal.classList.remove('active');
        video.pause();
        video.currentTime = 0;
        setHeaderDisplay(displayModeAfterClose);
        setHeaderTheme(themeAfterClose);
    });
})();

// ------------------------------------------------------------------------------
//      Sustainability video
// ------------------------------------------------------------------------------

const cityLoopVideo = document.getElementById('homepage-video') as HTMLVideoElement;
const cityTourVideo = document.getElementById('city-tour-video') as HTMLVideoElement;
const showTourButton = document.getElementById('city-tour-toggle')!;
const sectionTitle = document.getElementById('video-section-heading')!;
const videoSection = document.getElementById('video-section')!;

const scrollToIdString = 'video-section';
const TOUR_SCROLL_BLOCKER = 'city-tour';
const TOUR_VIDEO_END_CUT = 1;
const locationHref = '/#video-section';

videoPlayerOverLayService(
    cityLoopVideo,
    cityTourVideo,
    showTourButton,
    sectionTitle,
    videoSection,
    scrollToIdString,
    TOUR_SCROLL_BLOCKER,
    TOUR_VIDEO_END_CUT,
    locationHref,
    true,
);

// ------------------------------------------------------------------------------
//      News Item List + Spotlight Combination
// ------------------------------------------------------------------------------

const setNewsItemTopMargin = () => {
    const newsItemsList: HTMLElement | null = document.querySelector('.negative-top-margin');
    const spotLightSection: HTMLElement | null = document.querySelector('.homepage-spotlight__wrapper');

    if (newsItemsList && spotLightSection && document.body.clientWidth >= 1080) {
        const height = 300 + spotLightSection.offsetHeight;
        newsItemsList.style.marginTop = `-${height}px`;
    }

    if (newsItemsList && document.body.clientWidth < 1080) {
        newsItemsList.style.marginTop = '0px';
    }
};

windowSizes$.subscribe(setNewsItemTopMargin);
